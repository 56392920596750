.input-allocation {
    font-family: Arial, sans-serif;
    color: #e0e0e0;
    width: 100%;
    margin: 0 auto;
}

.new-allocation-button {
    background-color: #404040;
    color: #e0e0e0;
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    position: relative;
    width: fit-content;
}

.new-allocation-button:hover {
    background-color: #505050;
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #303030;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    z-index: 1000;
}

.dropdown-menu button {
    color: #e0e0e0;
    padding: 8px 12px;
    border: none;
    background: none;
    width: 100%;
    text-align: left;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.dropdown-menu button:hover {
    background-color: #404040;
}

.allocation-input-form {
    background-color: #202020;
    padding: 15px;
    border-radius: 4px;
    width: 55vw;
    margin-top: 10px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    box-sizing: border-box;
}

.input-header {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-bottom: 10px;
}

.date-picker,
.select-field {
    padding: 6px;
    border-radius: 4px;
    border: 1px solid #404040;
    background-color: #303030;
    color: #e0e0e0;
    font-size: 14px;
    flex: 1;
    min-width: 120px;
}

.input-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));
    gap: 10px;
}

.input-field {
    padding: 6px;
    border-radius: 4px;
    border: 1px solid #404040;
    background-color: #303030;
    color: #e0e0e0;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
}

.input-field.notes {
    grid-column: 1 / -1;
}

.button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
}

.add-to-batch-button,
.submit-button {
    background-color: #505050;
    color: #e0e0e0;
    padding: 6px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
    margin-left: 10px;
}

.add-to-batch-button:hover,
.submit-button:hover {
    background-color: #606060;
}

.batch-entries {
    margin-top: 15px;
    max-height: 150px;
    overflow-y: auto;
}

.batch-entry {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #303030;
    padding: 8px;
    border-radius: 4px;
    margin-bottom: 5px;
    font-size: 14px;
}

.remove-entry-button {
    background-color: #505050;
    color: #e0e0e0;
    border: none;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
}

.remove-entry-button:hover {
    background-color: #606060;
}

.error-message {
    color: #ff6b6b;
    margin-bottom: 10px;
    font-size: 14px;
}

.close-button {
    float: left;
    width: 20px;
    background: none;
    border: none;
    color: #e0e0e0;
    font-size: 20px;
    cursor: pointer;
}