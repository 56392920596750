@charset "UTF-8";

body {
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    line-height: 1.4;
    color: #dbdbdb;
}

button,
input,
textarea {
    transition: background-color 0.1s linear, border-color 0.1s linear, color 0.1s linear, box-shadow 0.1s linear, transform 0.1s ease;
}

h1 {
    font-size: 2.2em;
    margin-top: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
strong {
    color: #ffffff;
}

h1,
h2,
h3,
h4,
h5,
h6,
b,
strong,
th {
    font-weight: 600;
}

button,
input[type='submit'],
input[type='button'],
input[type='checkbox'] {
    cursor: pointer;
}

input,
textarea,
button,
select {
    color: #ffffff;
    background-color: #383838;
    font-family: inherit;
    font-size: inherit;
    padding: 5px;
    margin: 5px;
    border: none;
    border-radius: 6px;
    outline: none;
}

input:not([type='checkbox']):not([type='radio']),
select,
button,
textarea {
    -webkit-appearance: none;
}

textarea {
    margin-right: 0;
    width: 100%;
    box-sizing: border-box;
    resize: vertical;
}

button:hover,
input[type='submit']:hover,
input[type='button']:hover {
    background: #324759;
}

input:focus,
select:focus,
button:focus,
textarea:focus {
    box-shadow: 0 0 0 2px #0096bfab;
}

input[type='checkbox']:active,
input[type='radio']:active,
input[type='submit']:active,
input[type='button']:active,
button:active {
    transform: translateY(2px);
}

input:disabled,
select:disabled,
button:disabled,
textarea:disabled {
    cursor: not-allowed;
    opacity: .5;
}

::-webkit-input-placeholder {
    color: #a9a9a9;
}

:-ms-input-placeholder {
    color: #a9a9a9;
}

::-ms-input-placeholder {
    color: #a9a9a9;
}

::placeholder {
    color: #a9a9a9;
}

a {
    text-decoration: none;
    color: #41adff;
}

a:hover {
    text-decoration: underline;
}

code,
kbd {
    background: #1C1C1C;
    color: #ffbe85;
    padding: 5px;
    border-radius: 6px;
}

pre>code {
    padding: 10px;
    display: block;
    overflow-x: auto;
}

img {
    max-width: 100%;
}

hr {
    border: none;
    border-top: 1px solid #dbdbdb;
}

table {
    border-collapse: collapse;
    width: 100%;
}

td,
th {
    padding: 4px;
}

th {
    border-bottom: 1px solid #dbdbdb;
}

::-webkit-scrollbar {
    height: 10px;
    width: 10px;
}

::-webkit-scrollbar-track {
    background: #1C1C1C;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb {
    background: #324759;
    border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background: #415c73;
}