:root {
  --log-space: 1em;
  --white: #ffffff;
  --run-width: 12;
  --allo-width: 7;
  --greyish: #4D4D4D;
  --label-row-height: 43px;
  --tanks-width: 6;
  --tanks-height: 5;
}

/* body * {
  border: 1px solid green;
} */


body {
  text-align: center;
  background-color: #1C1C1C;
  color: grey;
  margin: 0;
}


.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: block;
}


.navBar {
  height: 100vh;
  width: 3.5em;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  background-color: #101010;
  overflow-x: hidden;
  padding-top: 1.5em;
  box-shadow: 1px 0px 0px 0px var(--greyish);
  /* Adds a line on the right side */
}

.trashIcon {
  width: 2em;
}

.navLinks button {
  width: 2.4em;
  background: 0;
  margin-top: 0.5em;
  opacity: 75%;
}

.Window-content {
  margin-left: 3.5em;
}

.App-header {
  margin-left: 4em;
  margin-right: 1em;
  height: 3em;
  display: flex;
  /* Establishes a flex container */
  justify-content: space-between;
  /* Aligns children with space between them */
  align-items: center;
  /* Centers items vertically within the container */
  /* Other styles like padding, background, etc., can go here */
}

h4 {
  font-size: 1.125em;
  line-height: 1.75em;
}

.BRIcon {
  display: block;
  /* Ensure the image is treated as a block element */
  margin: 0 auto 1em;
  /* Center the image and add margin below */
  width: 3em;
  /* Set an appropriate width, adjust as needed */
  height: auto;
  /* Maintain aspect ratio of the image */
}

.brew-form-header {
  display: flex;
}

.Profile {
  width: 2em;
  /* Set an appropriate width, adjust as needed */
  height: auto;
  /* Maintain aspect ratio of the image */
  margin-left: 1em;
}

.Labels {
  display: flex;
  /* Align children in a row */
  flex-wrap: nowrap;
  /* Prevent wrapping to a new line */
  align-items: center;
  /* Align items vertically */
  white-space: nowrap;
  /* Prevent text from wrapping */
  overflow-x: auto;
  /* Allow horizontal scrolling if needed */
  color: grey;
}


.navLinks {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 0.5em;
}


.New-input {
  display: flex;
  align-items: flex-start;
  margin-top: 1em;
  margin-bottom: 1em;
}

.new-Input-Form {
  display: flex;
  /* Align children in a row */
  flex-wrap: nowrap;
  /* Prevent wrapping to a new line */
  align-items: center;
  /* Align items vertically */
  white-space: nowrap;
  /* Prevent text from wrapping */
  overflow-x: auto;
  /* Allow horizontal scrolling if needed */
  margin-top: 1em;
}

.new-run-button {
  display: flex;
  margin-left: 1em;
  outline: 1px solid #9E9E9E;
  border-radius: 5px;
  width: fit-content;
  font-weight: 500;
  padding: 0.1em;
}

.new-run-button {
  position: relative;
  z-index: 3000;
  /* Ensure it's above other elements */
}

.info-row {
  outline: 1px solid var(--greyish);
  padding: 0.5em;
  background-color: #1d1d1d;
  display: flex;
}

.new-Input-Form .react-datepicker-popper {
  z-index: 1005;
}

.Input-run {
  display: grid;
  /* Align children in a row */
  grid-template-columns: repeat(1, 4em) repeat(1, 12em) repeat(1, 3em) repeat(1, 1fr) repeat(6, 3em);
  /* Adjusting column sizes */
}

.Input-allo {
  display: grid;
  /* Align children in a row */
  grid-template-columns: repeat(1, 5em) repeat(1, 12em) repeat(4, 3em);
  /* Adjusting column sizes */
}


.new-Input-Form-Values {
  gap: 10px;
  /* Spacing between grid items */
  max-width: 960px;
  text-align: left;
  color: black;
  background-color: whitesmoke;
  align-items: center;
  padding: 0.3em;
  margin-right: 0.3em;
}

.new-Input-Form ul {
  list-style-type: none;
  /* Removes bullet points from the list */
  padding-left: 0;
  /* Removes padding from the list */
}

.Logs {
  display: grid;
  /* Establish a flex container */
  flex-wrap: wrap;
  /* Allow children to wrap to the next line */
  justify-content: flex-start;
  /* Align children to the start of the main-axis */
  align-items: flex-start;
  /* Align children to the start of the cross-axis */
}


.login-page {
  max-width: 18em;
  display: inline-block;
  margin-top: 10em;
}

.login-page * {
  margin-top: 1em;
  width: -webkit-fill-available;
  width: -moz-available;
}

.add-Run-container {
  display: flex;
  justify-content: flex-end;
}


.log {
  position: relative;
  display: inline-flex;
  /* Each log is also a flex container */
  flex-direction: row;
  /* Arrange children (p elements) in a column */
  align-items: flex-start;
  /* Align children to the start of the cross-axis */
  margin-right: var(--log-space);
  /* Add space between logs */
  margin-bottom: 0.7em;
  /* Add space between rows of logs */
  max-width: calc(100% / 1 - var(--log-space));
  /* Example for 3 logs per row, adjust as needed */
  border-radius: 0.7em;
}

.new-Input-Form-Values-Request {
  /* Align children in a row */
  color: grey;
  border-radius: 1em;
  align-items: center;
  padding: 0.3em;
  margin-right: 0.3em;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* Adjust number of columns as needed */
  grid-gap: 10px;
  /* Space between grid items */
  width: 50vw;
}

.description {
  grid-column: 1 / -1;
  /* Makes description field span all columns */
}


.log p {
  text-align: center;
  max-height: 2em;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  font-weight: 600;
  color: #000;
}

.distributors-spacing {
  padding-left: 2em;
}

.row button {
  text-align: center;
  padding-left: 1em;
  padding-right: 1em;
  color: #FFF;
}

.label-urgency {
  margin-left: 7.2em;
  width: 6.9em;
}

.label-title {
  margin-left: 3em;
  width: 6.9em;
}

.row {
  display: flex;
}

.Error {
  max-width: 60em;
}

input {
  -webkit-appearance: none;
  /* For Chrome and Safari */
  -moz-appearance: none;
  /* For Firefox */
  appearance: none;
  /* Standard syntax */
  background: none;
  /* Add custom styles or background image here */
}

select {
  -webkit-appearance: none;
  /* For Chrome and Safari */
  -moz-appearance: none;
  /* For Firefox */
  appearance: none;
  /* Standard syntax */
  background: none;
  /* Add custom styles or background image here */
}


.button-row {
  display: flex;
  justify-content: left;
  margin-bottom: 0.7em;
}

.button-row button {
  margin: 0 0.3em;
  width: -webkit-fill-available;
  width: -moz-available;

  display: flex;
  /* Use flexbox */
  justify-content: center;
  /* Center content horizontally in flexbox */
  align-items: center;
  /* Center content vertically in flexbox */
}


.button-row.edges {
  justify-content: space-between;
}


.tanks {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.infobox label {
  display: block;
}

.infobox {
  width: 18.75em;
}

.small-box {
  width: 2em;
}

.medium-box {
  width: 7em;
}

.Logs {
  overflow-x: auto;
  width: 100%;
  /* or max-width if you want to constrain it */
}

.log-container {
  display: grid;
  /* Ensure this is wide enough to hold your full grid */
  max-height: calc(100vh - 5em);
  /* Set a height */
  overflow-y: auto;
  /* Enable vertical scrolling */
}

/* Hide scroll bars */
::-webkit-scrollbar {
  display: none;
}

.run-row {
  display: grid;
  grid-template-columns: 40px repeat(var(--run-width), 150px);
}

.allo-row {
  display: grid;
  grid-template-columns: 40px repeat(var(--allo-width), 150px);
}

.label-allo-row {
  display: grid;
  grid-template-columns: 40px repeat(var(--allo-width), 150px);
  position: sticky;
  height: var(--label-row-height);
  top: 0;
  z-index: 2;
  /* Adjust this if there's any top bar or padding */
  background-color: #2b2b2b;
  font-weight: 500;
  /* Ensures it stays on top of other content */
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  /* Optional: adds shadow for better separation */
}

.label-row {
  display: grid;
  grid-template-columns: 40px repeat(var(--run-width), 150px);
  height: var(--label-row-height);
  position: sticky;
  top: 0;
  z-index: 2;
  /* Adjust this if there's any top bar or padding */
  background-color: #2b2b2b;
  font-weight: 500;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  /* Optional: adds shadow for better separation */
}


.allo-row:hover,
.run-row:hover {
  background-color: #252424;
}

.delete-cell {
  color: slategrey;
}

.delete-cell,
.log-cell {
  text-align: center;
  border: 1px solid var(--greyish);
  margin: 0;
  padding: 0.4em;
  /* Adds lines between cells */
}

.log-cell {
  text-align: left;
}

.log-input,
.log-content {
  margin-top: 0.2em;
  margin-bottom: 0.2em;
}


.label-row :hover {
  position: relative;
  z-index: 1;
}

.sticky {
  position: fixed;
  top: 0;
  width: 100%;
  background-color: white;
  /* Adjust as needed */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  /* Optional: Add a shadow for better visibility */
}

.selected {
  border: 1px solid lightskyblue;
  /* Example: blue border for selected cells */
}

.date-picker {
  width: 100%;
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  z-index: 5000;
}

.beerbrand-dropdown {
  width: 100%;
  padding: 0.5em;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.month-header {
  display: flex;
  align-items: center;
  padding: 5px;
  cursor: pointer;
  background-color: #2b2b2b;
}

.month-header {
  position: sticky;
  top: var(--label-row-height);
  z-index: 1;
}

.month-header p {
  margin: 0;
  padding-left: 1em;
}

.filters {
  display: flex;
}

.rbc-btn-group {
  display: inline-flex !important;
}

.ReactTags__remove {
  width: auto;
  font-size: 1px;
}

.ReactTags__selected {
  display: flex;
}

.batch-Input {
  width: 100%;
}

.ReactTags__tags {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-radius: 4px;
  min-height: 38px;
}

.ReactTags__selected {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.ReactTags__tag {
  display: flex;
  align-items: center;
  background-color: #1c1c1c;
  color: white;
  border-radius: 2px;
  padding: 0px 4px;
  margin: 1px;
  font-size: 14px;
}

.ReactTags__remove {
  background: none;
  border: none;
  color: #999;
  margin-left: 5px;
  cursor: pointer;
  font-size: 16px;
}

.ReactTags__remove:hover {
  color: #666;
}

.ReactTags__tagInput {
  flex: 1;
  display: inline-block;
}

.ReactTags__tagInputField {
  width: 90%;
  border: none;
  outline: none;
  padding: 5px;
  font-size: 14px;
  min-width: 60px;
}

.reports-page {
  margin: 1em 3em 3em 3em;
}

.reports-page * {
  color: rgb(114, 114, 114) !important;
}



.user {
  display: flex;
  align-items: center;
}


.rbc-toolbar button {
  color: #9E9E9E;
}

.invisible-button {
  opacity: 0;
  pointer-events: none;
}

.brewSwitch {
  display: flex;
  margin-left: 1em;
}






.split-container {
  display: flex;
  height: 85vh;
}

.left-pane {
  width: 50%;
  display: flex;
  flex-direction: column;
  border-right: 0.5rem solid #373737;
  padding: 2%;
}

.right-pane {
  width: 50%;
  display: flex;
  height: fit-content;
  justify-content: center;
}

.button-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2%;
  gap: 2%;
  /* Optional: Adds consistent spacing between buttons */
}

.vessel-button {
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  font-size: 1rem;
  aspect-ratio: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vessel-button:hover {
  background-color: #0056b3;
}

.invisible-button {
  visibility: hidden;
  background-color: transparent;
  border: none;
  aspect-ratio: 1;
  /* Maintains the space for the hidden buttons */
}

.info-box {
  background-color: #101010;
  border-radius: 0.5rem;
  padding: 2%;
}

.info-box input,
textarea,
button,
select {
  margin: 0px;
}


.info-box h3,
p {
  margin: 5px;
}

.topub-window,
.events-window {
  display: flex;
  justify-content: center;
}

.topub-button,
.events-button {
  display: flex;
  align-items: center;
}

.topub-header,
.events-header {
  display: flex;

}

.topub-input input {
  width: 4em;
}

.pub-inventory {
  color: #7c7c7c;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}


.events-input input {
  width: 4em;
}

.topub-input,
.events-input {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  background-color: var(--greyish);
}

.topub-input * {
  font-size: xx-large;
}

.events-input * {
  font-size: xx-large;
}


.topub-batchview,
.events-batchview {
  background-color: var(--greyish);
}

.topub-batchview * {
  padding: 1em;
  font-size: large;
}

.events-batchview * {
  padding: 1em;
  font-size: large;
}

table tbody tr:nth-child(even) {
  background-color: #101010;
  /* Default background for even rows */
}

.feedback-Logs {
  background-color: var(--greyish);
}

.react-datepicker__tab-loop {
  z-index: 100;
}

.shorter-input {
  width: 5em;
}

.reconciliation-mode {
  display: flex;
}

.grey-text {
  color: var(--greyish);
}

.red-text {
  color: lightcoral;
}

.rbc-today {
  background-color: var(--greyish) !important;
}

.rbc-off-range-bg {
  background-color: #101010 !important;
}

.login-page form {
  text-align: left;
}


.glowing {
  animation: pulse 1.5s infinite;
  box-shadow: 0 0 15px rgba(255, 255, 0, 0.7), 0 0 30px rgba(255, 255, 0, 0.7), 0 0 45px rgba(255, 255, 0, 0.7);
}

@keyframes pulse {
  0% {
    transform: scale(1);
    box-shadow: 0 0 15px rgba(255, 255, 0, 0.7);
  }

  50% {
    transform: scale(1.05);
    box-shadow: 0 0 30px rgba(255, 255, 0, 0.9);
  }

  100% {
    transform: scale(1);
    box-shadow: 0 0 15px rgba(255, 255, 0, 0.7);
  }
}



.settings-page {
  padding: 20px;
}

.tables-container {
  display: flex;
  justify-content: left;
  text-align: left;
}

.table-container {
  margin: 10px;
  background-color: var(--greyish);
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.settings-brand-header {
  display: flex;
  justify-content: space-between;
}

h2 {
  font-size: 1.5rem;
  margin-bottom: 10px;
}

.data-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.data-table th,
.data-table td {
  padding: 12px 15px;
  text-align: left;
  border: 1px solid #ddd;
}

.data-table th {
  background-color: var(--greyish);
  font-weight: bold;
}

.data-table tr:nth-child(even) {
  background-color: var(--greyish);
}

.add-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.add-button:hover {
  background-color: #0056b3;
}





/* Distro Allo */
.distro-allo-container {
  display: flex;
  padding: 20px;
}

.left-section,
.right-section {
  width: 45%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.vertical-line {
  width: 1px;
  background-color: #ccc;
  height: 100%;
}

.batch-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.batch-button {
  width: 100%;
  margin: 5px 0;
  padding: 10px;
  text-align: left;
  background-color: var(--greyish);
  border: 1px solid #ccc;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.batch-button.selected {
  background-color: #000;
}

.batch-button:hover {
  background-color: #363636;
}

.batch-details {
  background-color: #000;
  padding: 5px;
  margin-top: 20px;
  margin-left: 20px;
}

.right-section {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  border-left: 1px solid #ccc;
}









.grid-container {
  display: grid;
  grid-template-columns: repeat(1, 10em) repeat(4, 5em) repeat(1, 10em);
  padding: 10px;
}

.distro-allo-row {
  display: contents;
  /* Each cell acts like an individual item in a grid row */
}

.cell {
  padding: 2px;
  border: 1px solid #ccc;
}

.bigger-cell {
  padding: 2px;
  border: 1px solid #ccc;
}


.span-cell {
  grid-column: span 2;
}

.add-new-row-cell {
  grid-column: span 6;
}

.cell input {
  width: 3em;
}

.cell h4 {
  margin: 0;
}

.bigger-cell input {
  width: 8em;
}


.run-sheet-header {
  display: flex;
  justify-content: center;
}

.run-sheets-list {
  display: grid;
  max-height: 25em;
  overflow-y: auto;
  overflow-x: hidden;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.run-sheet-list-inner {
  display: flex;
}



.notes-and-delete {
  display: flex;
}

.run-button {
  width: 13em;
}

.print-button {
  width: 5em;
  display: flex;
}

.tabs {
  display: flex;
}



.order-form table {
  width: 100%;
  border-collapse: collapse;
}

.order-form th,
.order-form td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.order-form th {
  background-color: #f46524;
  color: white;
}

.order-form td input {
  width: 60px;
  text-align: center;
}

.order-form td[rowspan] {
  vertical-align: middle;
}

.order-header {
  display: flex;
  justify-content: space-between;
}


.view-switch {
  display: flex;
}


/* RequestBoard.css */

.request-board {
  max-width: 600px;
  margin: 20px 20px;
  padding: 20px;
  background: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.request-title {
  text-align: center;
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 15px;
  color: #333;
}

.request-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.request-card {
  position: relative;
  display: flex;
  align-items: center;
  background: white;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-in-out;
}

.request-card:hover {
  transform: scale(1.02);
}

.delete-btn {
  position: absolute;
  top: 8px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.2rem;
  color: #ff4b4b;
  cursor: pointer;
  transition: color 0.2s;
}

.delete-btn:hover {
  color: #c93030;
}

.request-content {
  flex: 1;
  padding-right: 30px;
}

.request-date {
  font-size: 0.9rem;
  color: #666;
  margin-bottom: 5px;
}

.request-description {
  font-size: 1rem;
  color: #333;
  font-weight: 500;
}

.no-requests {
  text-align: center;
  font-size: 1rem;
  color: #888;
}